import * as React from "react";
import { Helmet } from "react-helmet";
import { Layout } from "../components/legacy";
import cn from "classnames";
import LOGO from "../../img/legacy/logo-white.svg";

import style from "./login.module.css";
import { Config } from "../constants";

const regexPassword = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{6,})/;

export default class ResetPasswordPage extends React.Component {
  state = {
    password: "",
    confirmPassword: "",
    resetSuccess: false,
    errorMessage: null,
    token: "",
  };

  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);
    let token = params.get("token");

    if (!token) {
      window.location.href = "/login";
    }
    this.setState({ token });
  };

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value.trim(),
    });
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { password, confirmPassword, token } = this.state;
    this.setState({ resetSuccess: false });

    if (!password || !confirmPassword) {
      this.setState({
        errorMessage: <span>Both fields must be filled</span>,
      });
    } else if (!regexPassword.test(password)) {
      this.setState({
        errorMessage: (
          <span>
            The password must have at least 6 characters and contains numbers
            and letters
          </span>
        ),
      });
    } else if (password !== confirmPassword) {
      this.setState({
        errorMessage: <span>The password must be the same on both fields</span>,
      });
    } else {
      this.setState({
        errorMessage: null,
      });
      let res = await fetch(
        `${Config.apiServer}/api/users/reset-password?access_token=${token}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify({ newPassword: password }),
        }
      );

      if (!res.ok) {
        if (res.status === 401) {
          this.setState({
            errorMessage: (
              <span>
                This link has either expired or is invalid. <br />
                <br /> <a href="/forgot">Click here</a> to send a new link to
                your email address.
              </span>
            ),
          });
        } else {
          this.setState({
            errorMessage: (
              <span>
                Account update failed. Please contact{" "}
                <a href="mailto:support@shipamax.com">support@shipamax.com</a>
              </span>
            ),
          });
        }
      } else {
        this.setState({ resetSuccess: true });

        window.setTimeout(() => {
          window.location.href = "/login";
        }, 4000);
      }
    }
  };

  render() {
    const { password, confirmPassword, resetSuccess, errorMessage } =
      this.state;
    return (
      <>
        <Layout>
          <Helmet>
            <title>Shipamax | Reset Password</title>
            <meta name="description" content="Reset your Shipamax account." />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.login__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <div className={style.login__bannerContent}>
                      <h1 className={style.login__heading}>
                        <img
                          className={cn(style.logo, style.logoLeft)}
                          src={LOGO}
                          alt="Logo"
                        />
                      </h1>
                      <form
                        className={cn(style.loginForm, style.resetForm)}
                        onSubmit={this.handleSubmit}
                      >
                        <input
                          type="password"
                          name="password"
                          value={password}
                          onChange={this.handleInputChange}
                          placeholder="New Password"
                          className={cn(style.formInput)}
                        />
                        <input
                          type="password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={this.handleInputChange}
                          placeholder="Confirm Password"
                          className={cn(style.formInput)}
                        />
                        <div
                          className={cn(style.form__loginError, {
                            [style.active]: resetSuccess,
                          })}
                        >
                          Password updated!
                          <br />
                          <br />
                          You will be redirected to the login page shortly.
                        </div>
                        {errorMessage && (
                          <div
                            className={cn(style.form__loginError, {
                              [style.active]: true,
                            })}
                          >
                            {errorMessage}
                          </div>
                        )}
                        <div className={style.form__buttons}>
                          <button type="submit" className={style.formSubmit}>
                            Reset Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}
